import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import StyledHero from '../components/StyledHero'
import RefiImg from '../images/refinancing.jpg';
import Banner from '../components/Banner';
import { FiCheckCircle } from 'react-icons/fi';
import { MdLocalPhone } from 'react-icons/md';
import { Helmet } from "react-helmet";



export default function Refinancing(props) {

    return (
        <div id="refiWrapper">
            <Helmet>
                <title>Refinancia Tu Propiedad</title>
                <meta name="description" content="Sun West Mortgage Company, Inc., offers a full line of refinancing options." />
            </Helmet>
            <StyledHero img={RefiImg}>
                <Banner title="Refinancia Tu Propiedad">
                </Banner>
            </StyledHero>
            <Container>
                <div className="wrapper-content bg-white rounded box-shadow">
                    <div className="pinside40 rounded">
                        <div className="product-tabs">
                            <Row>
                                <Col lg={12}>
                                    <div className="lender-details">
                                        <div className="lender-head-sections">
                                            <Row>
                                                <Col sm={12} md={3}>
                                                    <div className="lender-img">
                                                        <Image src="./assest/Logo-small.png" className="img-fluid" alt="Sun West Mortgage Company, Inc. logo" />
                                                    </div>
                                                </Col>
                                                <Col className="mb-3" sm={12} md={7}>
                                                    <h2 className="lender-name">Refinanciar tu hipoteca</h2>
                                                    <p>Sun West Mortgage Company, Inc., ofrece una línea completa de opciones para refinanciar. Nuestros expertos hipotecarios pueden discutir contigo los objetivos financieros y recomendar las mejores opciones. Cuando necesites usar el valor acumulado (&quot;equity&quot;) de tu casa para la educación universitaria de un hijo, adquirir un auto nuevo o para mejoras al hogar, existen opciones.</p>
                                                </Col>
                                                <Col sm={12} className="text-center">
                                                    <a className="btn btn-sm rounded box-shadow btn-primary" onClick={() => {
                                                        props.setModalShow(true);
                                                    }}>Solicita Ahora</a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="lender-apply">
                                            <Row>
                                                <Col sm={12}>
                                                    <h2 className="mb30">Ventajas de refinanciar tu hipoteca:</h2>
                                                    <ul className="listnone bullet">
                                                        <li><FiCheckCircle className="bullet-check-circle-default" />Al refinanciar, tienes acceso al valor acumulado de tu hogar a través de la opción de refinanciamiento &quot;Cash-Out&quot;.</li>
                                                        <li><FiCheckCircle className="bullet-check-circle-default" />A través de la opción &quot;Rate &amp; Term&quot;, puedes refinanciar tu hogar para obtener un interés más bajo. Es posible que la tasa de interés haya bajado desde la última vez que financiaste tu hipoteca.</li>
                                                        <li><FiCheckCircle className="bullet-check-circle-default" />Tus pagos mensuales bajan al refinanciar tu hipoteca a un interés más bajo</li>
                                                        <li><FiCheckCircle className="bullet-check-circle-default" />Cuando refinancias tu hipoteca con el objetivo de consolidar deudas, tendrás dinero adicional para pagar otras deudas, como tarjetas de crédito. En comparación a una hipoteca, las tarjetas de crédito usualmente cobran una tasa de interés más alta.</li>
                                                    </ul>
                                                    <p>Nuestros profesionales hipotecarios con amplia experiencia y licencias en hipotecas están listos para contestar cualquier pregunta que puedas tener. Por favor llámanos al <a href="tel:+17877869378"><MdLocalPhone />&nbsp;787&#8209;SUN&#8209;WEST.</a></p>
                                                    <div className="text-center">
                                                        <a className="btn btn-primary btns"
                                                            onClick={() => {
                                                                props.setModalShow(true);
                                                            }}>Solicita Ahora</a>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}
