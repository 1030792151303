import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Collapse from 'react-bootstrap/Collapse';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StyledHero from '../components/StyledHero'
import FhaImg from '../images/fha-header.jpg';
import Banner from '../components/Banner';
import { Link } from 'react-router-dom';
import { FiCheckCircle } from 'react-icons/fi';
import { MdLabelOutline } from 'react-icons/md'
import { MdLocalPhone } from 'react-icons/md'
import { GoLinkExternal } from 'react-icons/go'
import styled from 'styled-components';
import { Helmet } from "react-helmet";



const ArchonButton = styled.button`
  background: transparent;
  color: #F5911E;
  font-size: 1em;
  padding-bottom: 10px;
  border: none;

  &:hover {
    opacity: .8;
  }

`;


export default function FHA(props) {
    const [open, setOpen] = useState(false);

    return (
        <div id="fhaWrapper">
            <Helmet>
                <title>Préstamo FHA</title>
                <meta name="description" content="FHA loan programs are established to make it easier to obtain a mortgage for people who would normally find it difficult to qualify for a mortgage due to their income level, credit history, or their ability to put down a sufficient amount of down payment." />
            </Helmet>
            <StyledHero img={FhaImg}>
                <Banner title="Hipoteca FHA">
                </Banner>
            </StyledHero>
            <Container>
                <div className="wrapper-content bg-white rounded box-shadow">
                    <div className="pinside40 rounded">
                        <div className="product-tabs">
                            <Row>
                                <Col lg={12} className="nopadding">
                                    <div className="tab-content">
                                        <div role="tabpanel" className="tab-pane active" id="overview">
                                            <h2>Sobre Hipoteca FHA</h2>
                                            <p>Sun West Mortgage Company, Inc., es un prestador aprobado de FHA, con una línea completa de productos hipotecarios FHA, asegurados por el Gobierno Federal.</p><p>Los programas de préstamos FHA se establecen para facilitarle un préstamo hipotecario a los compradores que se les hace difícil cualificar para una hipoteca, por su salario, historial crediticio o capacidad de dar el pronto. Este préstamo es perfecto para la compra de un primer hogar o para refinanciar.</p>
                                            <h3>Beneficios claves del programa FHA</h3>
                                            <ul className="listnone bullet">
                                                <li><FiCheckCircle className="bullet-check-circle-default" />Requiere un pronto más bajo (tan bajo como 3.5% en la mayoría de los productos)</li>
                                                <li><FiCheckCircle className="bullet-check-circle-default" />Acepta puntuación de crédito más baja. Sun West Mortgage Company ha logrado financiar préstamos FHA con puntuaciones de crédito no tan excelentes y un FICO* tan bajo como 500.</li>
                                            </ul>
                                            <small>*Fair Isaac Corporation. Esta es una marca particular de puntuación de crédito que la mayoría de los prestadores utilizan para decidir si le ofrecen un préstamo o tarjeta de crédito a una persona.</small><br /><br /><br />
                                            <h2>Opciones de préstamos FHA</h2><br />
                                            <h2>FHA 203B</h2>
                                            <p>203(b) es el préstamo FHA para hogares unifamiliares para comprar o refinanciar una residencia principal. Este préstamo hipotecario es financiado por una institución financiera, como un banco hipotecario, un banco, cooperativas de ahorro y crédito, y la Administración de Vivienda Federal (HUD, por sus siglas en inglés) asegura la hipoteca.</p>

                                            <ArchonButton onClick={() => setOpen(!open)} aria-controls="collapse-fade-text" aria-expanded={open}><MdLabelOutline className="rightIcon" />¿Quién es elegible para un FHA 203(b)? De acuerdo al FHA:</ArchonButton>
                                            <Collapse in={open}>
                                                <div id="collapse-fade-text">
                                                    <p>El prestatario debe reunir las cualificaciones crediticias estándares de FHA.</p>
                                                    <ul className="listnone bullet">
                                                        <li><FiCheckCircle className="bullet-check-circle-default" />El prestatario es elegible para 96.5% de financiamiento aproximadamente (Se requiere un 3,5% en efectivo del prestatario).</li>
                                                        <li><FiCheckCircle className="bullet-check-circle-default" />El prestatario puede financiar la prima adelantada del seguro hipotecario en la hipoteca. El prestatario también será responsable de pagar la prima anual.</li>
                                                        <li><FiCheckCircle className="bullet-check-circle-default" />Propiedades elegibles son estructuras de una a cuatro unidades.</li>
                                                    </ul>
                                                </div>
                                            </Collapse>


                                            <h2 className="mt-4">FHA 203K</h2>
                                            <p>Esta opción de préstamo FHA le permite a propietarios rehabilitar su hogar. El préstamo puede ser utilizado para otros proyectos de mejoras al hogar, tales como cuartos adicionales o hacer sus hogares más eficientes en energía. Esta opción puede ser utilizada tanto cuando compras tu hogar nuevo o como refinanciamiento de la hipoteca existente.</p><br />
                                            <h2>FHA 203H</h2>
                                            <p>El FHA 203H ayuda a víctimas en áreas de desastre a recuperarse, al hacer el proceso de obtener un préstamo hipotecario para convertirse en propietarios o restablecerse como propietarios más fácil. Las personas son elegibles para este programa si su hogar está localizado en áreas designadas por el Presidente como zonas de desastre y si sus hogares fueron destruidos o dañados al punto que la reconstrucción o reemplazo es necesario.</p>
                                            <ul className="listnone bullet">
                                                <li><FiCheckCircle className="bullet-check-circle-default" />No se requiere pronto. El prestatario es elegible para 100% porciento de financiamiento (cero pago inicial del prestatario). Las gastos de cierre y pre-pagados deben ser pagados por el prestatario en efectivo o a través de un &quot;premium pricing&quot;, o por el vendedor, sujeto a una limitación de 6% porciento en concesiones del vendedor.</li>
                                                <li> <FiCheckCircle className="bullet-check-circle-default" />Las hipotecas aseguradas pueden ser utilizadas para financiar la compra o reconstrucción de una casa unifamiliar que será la residencia principal del propietario.</li>
                                            </ul><br />
                                            <h2><i>Streamline</i></h2>
                                            <p>El préstamo de refinanciamiento FHA <i>Streamline</i> te ayuda a bajar el interés anual, el pago mensual, o el término de tu préstamo FHA actual, y requiere menos documentación que los programas tradicionales. Además, no requiere que tu propiedad sea tasada.</p>

                                            <p>Para una lista de los documentos requeridos para el cierre, por favor procede a: <Link to="./Documents">DOCUMENTOS&nbsp;<GoLinkExternal /></Link></p>
                                            <p>Nuestros profesionales hipotecarios con amplia experiencia y licencias en hipotecas están listos para contestar cualquier pregunta que puedas tener. Por favor llámanos al <a href="tel:+17877869378"><MdLocalPhone />&nbsp;787&#8209;SUN&#8209;WEST.</a></p>
                                            <div className="text-center">
                                                <a className="btn btn-primary btns apply_btn_color"
                                                    onClick={() => {
                                                        props.setModalShow(true);
                                                    }}>Solicita Ahora</a>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}
