import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import StyledHero from "../components/StyledHero";
import RatesImg from "../images/disclaimer.jpeg";
import { Helmet } from "react-helmet";
import axios from "axios";
import parser from "html-react-parser";

export default function Disclaimer() {
  const [termsAndConditionsData, setTermsAndConditionsData] = useState();
  const [licenseData, setLicenseData] = useState([]);

  useEffect(() => {
    const fetchTermsAndConditions = async () => {
      try {
        await axios
          .post(process.env.REACT_APP_TERMS_AND_CONDITIONS, {
            websiteName: "swmc.com",
          })
          .then((response) => {
            // console.log(JSON.stringify(response.data.data));
            setTermsAndConditionsData(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (e) {
        console.log(e);
      }
    };

    fetchTermsAndConditions();
  }, []);

  useEffect(() => {
    const fetchLicenseData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_DISCLAIMER_JSON);
        setLicenseData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchLicenseData();
  }, []);

  return (
    <div id="disclaimersWrapper">
      <Helmet>
        <title>Licencias</title>
        <meta
          name="description"
          content="Lowrates PR, Lowrates US disclaimers"
        />
      </Helmet>
      <StyledHero img={RatesImg}></StyledHero>
      <Container>
        <div className="wrapper-content bg-white rounded box-shadow">
          <div className="pinside40 rounded">
            <Row>
              <Col className="text-center">
                {termsAndConditionsData ? (
                  <div className="mb-5" style={{ color: "#479fdd" }}>
                    {termsAndConditionsData.angelaidisclaimer.map(
                      (angelaidisclaimer, i) => (
                        <p key={i}>{parser(angelaidisclaimer)}</p>
                      )
                    )}
                    <p>
                      <strong>
                        Sun West Mortgage Company, Inc. NMLS ID 3277
                        <br />
                        State Licenses
                        <br />
                        Licenses can be verified at:{" "}
                        <a href="http://www.nmlsconsumeraccess.org">
                          {" "}
                          www.nmlsconsumeraccess.org{" "}
                        </a>
                      </strong>
                    </p>
                    <p>
                      <strong>
                        In all jurisdictions, the main licensed location of Sun
                        West Mortgage Company, Inc. is 18303 Gridley Rd.,
                        Cerritos, CA 90703, Phone: (800) 453-7884.
                      </strong>
                    </p>
                  </div>
                ) : (
                  ""
                )}
                <Table responsive="md" striped bordered>
                  <thead>
                    <tr>
                      <th>State</th>
                      <th>License Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {licenseData.map((license, index) => (
                      <tr key={index}>
                        <td>{license.state}</td>
                        <td>{parser(license.licenseDescription)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
}
