import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import StyledHero from '../components/StyledHero'
import RatesImg from '../images/rates.jpeg';
import Banner from '../components/Banner';
import {Helmet} from "react-helmet";
import { HashLink as Link } from 'react-router-hash-link';


let parseString = require('xml2js').parseString;


export default class Rates extends Component {
    constructor(props){
        super(props);
        this.state = {
            loanProgramArray : "",
            con10Show: false,
            con15Show: false,     
            con30Show: false,
            fha15Show: false,     
            fha30Show: false, 
            va15Show: false,     
            va30Show: false,
            usda30Show: false
        }
    }


    // Methods to show and close the modals

    handleCloseConv10 = () => this.setState({con10Show: false});
    handleShowConv10 = () => this.setState({con10Show: true});
    handleCloseConv15 = () => this.setState({con15Show: false});
    handleShowConv15 = () => this.setState({con15Show: true});
    handleCloseConv30 = () => this.setState({con30Show: false});
    handleShowConv30 = () => this.setState({con30Show: true});

    handleCloseFha15 = () => this.setState({fha15Show: false});
    handleShowFha15 = () => this.setState({fha15Show: true});
    handleCloseFha30 = () => this.setState({fha30Show: false});
    handleShowFha30 = () => this.setState({fha30Show: true});

    handleCloseVa15 = () => this.setState({va15Show: false});
    handleShowVa15 = () => this.setState({va15Show: true});
    handleCloseVa30 = () => this.setState({va30Show: false});
    handleShowVa30 = () => this.setState({va30Show: true});

    handleCloseUsda30 = () => this.setState({usda30Show: false});
    handleShowUsda30 = () => this.setState({usda30Show: true});


    componentDidMount() {
      fetch("https://s39d9gulg1.execute-api.us-east-1.amazonaws.com/dev")
      .then( response => response.text())
      .then(
          // handle the result
          (result) => {
              this.setState({
                loanProgramArray : result
              });
          }
      )
    }

    render() {


    const xmlData = this.state.loanProgramArray;
    let returnArray = [];
    
     parseString( xmlData, function( err, result ) { 
        let jsonData = JSON.stringify( result, undefined, 3 );
        let data = JSON.parse(jsonData);
        
        if (data != null)
        
        {
          let dataObj = data.loan_rates['loan_program'];
              for (let i = 0; i < dataObj.length; i++) {
                let priceId = dataObj[i].priceId;
                let date = dataObj[i].date;
                date = new Date(Date(date));

                //  time is in 24 hour format, we must convert it to 12 hour
                let options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
                let currentDate = new Intl.DateTimeFormat('en-US', options).format(date);
                let time = new Date(date).toLocaleTimeString('en-US', {timeZone: "America/New_York"});
                let rate = dataObj[i].rate;
                let apr = dataObj[i].apr;
              
            returnArray[i] = [priceId,currentDate,time,rate,apr];
          
          }  
        }
      });
      
        return (
            <div>
              <Helmet>
                <title>Today's Rates</title>
                <meta name="description" content="Attractive mortgage rates without compromise in service." />
            </Helmet>
            <StyledHero img={RatesImg}>
                <Banner title="Today's Rates">
                </Banner>
            </StyledHero>  


          {/* <!-- Conventional 10 Year Fixed Modal --> */}
            <Modal id="convensional10Year" size="lg" centered show={this.state.con10Show} onHide={this.handleCloseConv10}>
                <Modal.Header className="Modal-colors">
                  <Modal.Title>Conventional 10 Year Fixed</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <strong align="center">Important disclosures, assumptions, and information</strong><br /><br />
                  <p>The interest rate shown is with no points for a 10 year fixed,<strong>&quot;no cash-out&quot;</strong> refinance loan and assumes a minimum FICO score of 740 with a maximum <strong>loan-to-value ratio of 80%</strong> on a primary residence. The actual interest rate, APR, and payment may vary based on the specific terms of the loan selected, verification of information, your credit history, the location and type of property, and other factors as determined by Sun West Mortgage Company, Inc. Rates shown is based on a 45-day lock-in period. Not available in all states. Rates are subject to change daily without notice. The borrower may choose to apply for a loan with (1) other fee options in which points and/or fees are charged to obtain a lower interest rate, or (2) higher rate to obtain a credit towards certain eligible closing costs. Payment does not include taxes and insurance premiums. The actual payment amount will be greater. Some state and county maximum loan amount restrictions may apply.</p>
                </Modal.Body>
                <Modal.Footer>
                  <span className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseConv10}>
                  <span aria-hidden="true">&times;</span>
                  </span>
                </Modal.Footer>
            </Modal>  
	        {/* <!-- Modal End --> */}

	        {/* <!-- Conventional 15 Year Fixed Modal --> */}
            <Modal id="convensional15Year" size="lg" centered show={this.state.con15Show} onHide={this.handleCloseConv15}>
              <Modal.Header className="Modal-colors">
                <Modal.Title>Conventional 15 Year Fixed</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <strong align="center">Important disclosures, assumptions, and information</strong><br /><br />
                <p>The interest rate shown is with no points for a 15 year fixed, <strong>&quot;no cash-out&quot;</strong> refinance loan and assumes a minimum FICO score of 740 with a maximum <strong>loan-to-value ratio of 80%</strong> on a primary residence. The actual interest rate, APR, and payment may vary based on the specific terms of the loan selected, verification of information, your credit history, the location and type of property, and other factors as determined by Sun West Mortgage Company, Inc. Rates shown is based on a 45-day lock-in period. Not available in all states. Rates are subject to change daily without notice. The borrower may choose to apply for a loan with (1) other fee options in which points and/or fees are charged to obtain a lower interest rate, or (2) higher rate to obtain a credit towards certain eligible closing costs. Payment does not include taxes and insurance premiums. The actual payment amount will be greater. Some state and county maximum loan amount restrictions may apply.</p>
              </Modal.Body>
              <Modal.Footer>
                <span className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseConv15}>
                <span aria-hidden="true">&times;</span>
                </span>
              </Modal.Footer>
          </Modal>
        {/* <!-- Modal End --> */}

	      {/* <!-- Conventional 30 Year Fixed Modal --> */}
          <Modal id="convensional30Year" size="lg" centered show={this.state.con30Show} onHide={this.handleCloseConv30}>
            <Modal.Header className="Modal-colors">
              <Modal.Title>Conventional 30 Year Fixed</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <strong align="center">Important disclosures, assumptions, and information</strong><br /><br />
              <p>The interest rate shown is with no points for a 30 year fixed, <strong>&quot;no cash-out&quot;</strong> refinance loan and assumes a minimum FICO score of 740 with a maximum <strong>loan-to-value ratio of 75%</strong> on a primary residence. The actual interest rate, APR, and payment may vary based on the specific terms of the loan selected, verification of information, your credit history, the location and type of property, and other factors as determined by Sun West Mortgage Company, Inc. Rates shown is based on a 45-day lock-in period. Not available in all states. Rates are subject to change daily without notice. The borrower may choose to apply for a loan with (1) other fee options in which points and/or fees are charged to obtain a lower interest rate, or (2) higher rate to obtain a credit towards certain eligible closing costs. Payment does not include taxes and insurance premiums. The actual payment amount will be greater. Some state and county maximum loan amount restrictions may apply.</p>
            </Modal.Body>
            <Modal.Footer>
              <span className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseConv30}>
              <span aria-hidden="true">&times;</span>
              </span>
            </Modal.Footer>
          </Modal>
	      {/* <!-- Modal End --> */}

			  {/* <!-- FHA 15 Year Fixed Modal --> */}
        <Modal id="fha15Year" size="lg" centered show={this.state.fha15Show} onHide={this.handleCloseFha15}>
            <Modal.Header className="Modal-colors">
              <Modal.Title>FHA 15 Year Fixed</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <strong align="center">Important disclosures, assumptions, and information</strong><br /><br />
              <p>The interest rate shown is with no points for a 15 year fixed and assumes a minimum FICO score of 680 with a maximum <strong>loan-to-value ratio of 96.5%</strong> on a purchase of an owner occupied single family residence. The actual interest rate, APR, and payment may vary based on the specific terms of the loan selected, verification of information, your credit history, the location and type of property, and other factors as determined by Sun West, Inc. Rates shown is based on a 45-day lock-in period. Not available in all states. Rates are subject to change daily without notice. The borrower may choose to apply for a loan with (1) other fee options in which points and/or fees are charged to obtain a lower interest rate, or (2) higher rate to obtain a credit towards certain eligible closing costs.</p>
            </Modal.Body>
            <Modal.Footer>
              <span className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseFha15}>
              <span aria-hidden="true">&times;</span>
              </span>
            </Modal.Footer>
          </Modal>
	      {/* <!-- Modal End --> */}

				 {/* <!-- FHA 30 Year Fixed Modal --> */}
         <Modal id="fha30Year" size="lg" centered show={this.state.fha30Show} onHide={this.handleCloseFha30}>
            <Modal.Header className="Modal-colors">
              <Modal.Title>FHA 30 Year Fixed</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <strong align="center">Important disclosures, assumptions, and information</strong><br /><br />
              <p>The interest rate shown is with no points for a 30 year fixed and assumes a minimum FICO score of 680 with a maximum <strong>loan-to-value ratio of 96.5%</strong> on a purchase of primary residence. The actual interest rate, APR, and payment may vary based on the specific terms of the loan selected, verification of information, your credit history, the location and type of property, and other factors as determined by Sun West Mortgage Company, Inc. Rates shown is based on a 45-day lock-in period. Not available in all states. Rates are subject to change daily without notice. The borrower may choose to apply for a loan with (1) other fee options in which points and/or fees are charged to obtain a lower interest rate, or (2) higher rate to obtain a credit towards certain eligible closing costs. Payment does not include taxes and insurance premiums. The actual payment amount will be greater. Some state and county maximum loan amount restrictions may apply.</p>
            </Modal.Body>
            <Modal.Footer>
              <span className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseFha30}>
              <span aria-hidden="true">&times;</span>
              </span>
            </Modal.Footer>
          </Modal>
	     {/* <!-- Modal End --> */}

        {/* <!-- VA 15 Year Fixed Modal --> */}
          <Modal id="va15Year" size="lg" centered show={this.state.va15Show} onHide={this.handleCloseVa15}>
            <Modal.Header className="Modal-colors">
              <Modal.Title>VA 15 Year Fixed</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <strong align="center">Important disclosures, assumptions, and information</strong><br /><br />
              <p>The interest rate shown is with no points for a 15 year fixed and assumes a minimum FICO score of 680 with a maximum <strong>loan-to-value ratio of 100%</strong> on a purchase of an owner occupied single family residence. The actual interest rate, APR, and payment may vary based on the specific terms of the loan selected, verification of information, your credit history, the location and type of property, and other factors as determined by Sun West, Inc. Rates shown is based on a 45-day lock-in period. Not available in all states. Rates are subject to change daily without notice. The borrower may choose to apply for a loan with (1) other fee options in which points and/or fees are charged to obtain a lower interest rate, or (2) higher rate to obtain a credit towards certain eligible closing costs.</p>
            </Modal.Body>
            <Modal.Footer>
              <span className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseVa15}>
              <span aria-hidden="true">&times;</span>
              </span>
            </Modal.Footer>
          </Modal>
	      {/* <!-- Modal End --> */}

        {/* <!-- VA 30 Year Fixed Modal --> */}
          <Modal id="va30Year" size="lg" centered show={this.state.va30Show} onHide={this.handleCloseVa30}>
            <Modal.Header className="Modal-colors">
              <Modal.Title>VA 30 Year Fixed</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <strong align="center">Important disclosures, assumptions, and information</strong><br /><br />
              <p>The interest rate shown is with no points for a 30 year fixed and assumes a minimum FICO score of 680 with a maximum <strong>loan-to-value ratio of 100%</strong> on a purchase of primary residence. The actual interest rate, APR, and payment may vary based on the specific terms of the loan selected, verification of information, your credit history, the location and type of property, and other factors as determined by Sun West Mortgage Company, Inc. Rates shown is based on a 45-day lock-in period. Not available in all states. Rates are subject to change daily without notice. The borrower may choose to apply for a loan with (1) other fee options in which points and/or fees are charged to obtain a lower interest rate, or (2) higher rate to obtain a credit towards certain eligible closing costs. Payment does not include taxes and insurance premiums. The actual payment amount will be greater. Some state and county maximum loan amount restrictions may apply.</p>
            </Modal.Body>
            <Modal.Footer>
              <span className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseVa30}>
              <span aria-hidden="true">&times;</span>
              </span>
            </Modal.Footer>
          </Modal>
	      {/* <!-- Modal End --> */}

        {/* <!-- USDA 30 Year Fixed Modal --> */}
        <Modal id="usda30Year" size="lg" centered show={this.state.usda30Show} onHide={this.handleCloseUsda30}>
            <Modal.Header className="Modal-colors">
              <Modal.Title>USDA 30 Year Fixed</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <strong align="center">Important disclosures, assumptions, and information</strong><br /><br />
              <p>The interest rate shown is with no points for a 30 year fixed and assumes a minimum FICO score of 680 with a maximum <strong>loan-to-value ratio of 100%</strong> on purchase of a primary residence. The actual interest rate, APR, and payment may vary based on the specific terms of the loan selected, verification of information, your credit history, the location and type of property, and other factors as determined by Sun West Mortgage Company, Inc. Rates shown is based on a 45-day lock-in period. Not available in all states. Rates are subject to change daily without notice. The borrower may choose to apply for a loan with (1) other fee options in which points and/or fees are charged to obtain a lower interest rate, or (2) higher rate to obtain a credit towards certain eligible closing costs. Payment does not include taxes and insurance premiums. The actual payment amount will be greater. Some state and county maximum loan amount restrictions may apply.</p>
            </Modal.Body>
            <Modal.Footer>
              <span className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseUsda30}>
              <span aria-hidden="true">&times;</span>
              </span>
            </Modal.Footer>
          </Modal>
	      {/* <!-- Modal End --> */}
          <Container>
          <div className="wrapper-content bg-white rounded box-shadow">
                    <div className="pinside40 rounded">
            <Row>
              <Col className="text-center">
                  <div className="p-3">
                    <p className="mb-0">Atractivas tasas hipotecarias sin compromiso en el servicio. ¡La satisfacción total del cliente es nuestro objetivo número 1!</p>
                    <small style={{color: "#004ca4"}}>*No aplica a Puerto Rico.</small><br/>
                    <span className="text-muted">
                    { returnArray[0] ? returnArray[0][1]: null } <br />
                    { returnArray[0] ? returnArray[0][2]+" EST": null } 
                    </span>
                  </div>
                <Table className="rates-table" responsive="md" striped bordered>
                <thead>
                        <tr>
                            <th className="rates_headingth1">Programa</th>
                            <th className="rates_headingth2">Tasa</th>
                            <th className="rates_headingth3">APR</th>
                            <th className="rates_headingth4">Puntos</th>
                            <th className="rates_headingth5">Supuestos</th>
                        </tr>
                    </thead>
                    <tbody>
                      { returnArray[0] || returnArray[1] || returnArray[2] ? 
                        <tr className="text-center">
                            <th colSpan="5">
                                <h3>Convencionales</h3>
                                <small>80% LTV, 740 FICO, Primary Occupancy, No Cash Out</small>
                            </th>
                        </tr>
                      : null }
                    {/* Conventional 10 Years */}
                      { returnArray[0] ?
                        <tr>
                            <td><img src="https://d2w24n4g34usfg.cloudfront.net/lowratespr/images/LowRates-IconAsset4-8.png" className="d-none d-sm-none d-md-inline-block img-fluid" alt="LR Logo" />
                            <Link smooth to="./fixedmortgage#10yrfixedcon" target="_blank" rel="noopener noreferrer">10-Year Fixed</Link>
                            </td>
                            <td >
                               { Number(returnArray[0][3]).toFixed(3)+"%" }
                            </td>
                            <td >
                              { Number(returnArray[0][4]).toFixed(3)+"%" }
                            </td>
                            <td >
                            0.0000
                            </td>
                            <td >
                                <span className="modalLink" data-toggle="modal" data-target="#convensional10Year" onClick={this.handleShowConv10}>View</span>
                            </td>
                        </tr>
                      : null }
                    {/* Conventional 15 Years */}
                      { returnArray[1] ?
                        <tr>
                            <td><img src="https://d2w24n4g34usfg.cloudfront.net/lowratespr/images/LowRates-IconAsset4-8.png" className="d-none d-sm-none d-md-inline-block img-fluid" alt="LR Logo" /><Link smooth to="./fixedmortgage#15yrfixedcon" target="_blank" rel="noopener noreferrer">15-Year Fixed</Link></td>
                            <td >
                              { Number(returnArray[1][3]).toFixed(3)+"%" }
                            </td>
                            <td >
                              { Number(returnArray[1][4]).toFixed(3)+"%" }
                            </td>
                            <td >
                            0.0000
                            </td>
                            <td >
                              <span className="modalLink" data-toggle="modal" data-target="#convensional15Year" onClick={this.handleShowConv15}>View</span>
                            </td>
                        </tr>
                      : null }
                    {/* Conventional 30 Years */}
                      { returnArray[2] ?
                        <tr>
                            <td><img src="https://d2w24n4g34usfg.cloudfront.net/lowratespr/images/LowRates-IconAsset4-8.png" className="d-none d-sm-none d-md-inline-block img-fluid" alt="LR Logo" /><Link smooth to="./fixedmortgage#30yrfixedcon" target="_blank" rel="noopener noreferrer">30-Year Fixed</Link></td>
                            <td >
                              { Number(returnArray[2][3]).toFixed(3)+"%" }
                            </td>
                            <td >
                              { Number(returnArray[2][4]).toFixed(3)+"%" }
                            </td>
                            <td >
                            0.0000
                            </td>
                            <td >
                              <span className="modalLink" data-toggle="modal" data-target="#convensional30Year" onClick={this.handleShowConv30}>View</span>
                            </td>
                        </tr>
                      : null }
                    
                      { returnArray[3] || returnArray[4] ? 
                        <tr className="text-center">
                            <th colSpan="5">
                                <h3>FHA</h3>
                                <small>96.5% LTV, 680 FICO, Primary Occupancy, Purchase</small>
                            </th>
                        </tr>
                      : null }
                    {/* Fha 15 Years */}
                      { returnArray[3] ?
                        <tr>
                            <td><img src="https://d2w24n4g34usfg.cloudfront.net/lowratespr/images/LowRates-IconAsset4-8.png" className="d-none d-sm-none d-md-inline-block img-fluid" alt="LR Logo" /><Link smooth to="./fixedmortgage#15yrfixedfha" target="_blank" rel="noopener noreferrer">15-Year Fixed</Link></td>
                            <td >
                               { Number(returnArray[3][3]).toFixed(3)+"%" }
                            </td>
                            <td >
                              { Number(returnArray[3][4]).toFixed(3)+"%" }
                            </td>
                            <td >
                            0.0000
                            </td>
                            <td >
                              <span className="modalLink" data-toggle="modal" data-target="#fha15Year" onClick={this.handleShowFha15}>View</span>
                            </td>
                        </tr>
                      : null }
                    {/* Fha 30 Years */}
                      { returnArray[4] ?
                        <tr>
                            <td><img src="https://d2w24n4g34usfg.cloudfront.net/lowratespr/images/LowRates-IconAsset4-8.png" className="d-none d-sm-none d-md-inline-block img-fluid" alt="LR Logo" /><Link smooth to="./fixedmortgage#30yrfixedfha" target="_blank" rel="noopener noreferrer">30-Year Fixed</Link></td>
                            <td >
                              { Number(returnArray[4][3]).toFixed(3)+"%" }
                            </td>
                            <td >
                              { Number(returnArray[4][4]).toFixed(3)+"%" }
                            </td>
                            <td >
                            0.0000
                            </td>
                            <td >
                              <span className="modalLink" data-toggle="modal" data-target="#fha30Year" onClick={this.handleShowFha30}>View</span>
                            </td>
                        </tr>
                      : null }

                      { returnArray[5] || returnArray[6] ? 
                        <tr className="text-center">
                            <th colSpan="5">
                                <h3>VA</h3>
                                <small>100% LTV, 680 FICO, Primary Occupancy, Purchase</small>
                            </th>
                        </tr>
                      : null }
                    {/* Va 15 Years */}
                      { returnArray[5] ?
                        <tr>
                            <td><img src="https://d2w24n4g34usfg.cloudfront.net/lowratespr/images/LowRates-IconAsset4-8.png" className="d-none d-sm-none d-md-inline-block img-fluid" alt="LR Logo" /><Link smooth to="./fixedmortgage#15yrfixedva" target="_blank" rel="noopener noreferrer">15-Year Fixed</Link></td>
                            <td >
                               { Number(returnArray[5][3]).toFixed(3)+"%" }
                            </td>
                            <td >
                              { Number(returnArray[5][4]).toFixed(3)+"%" }
                            </td>
                            <td >
                            0.0000
                            </td>
                            <td >
                              <span className="modalLink" data-toggle="modal" data-target="#va15Year" onClick={this.handleShowVa15}>View</span>
                            </td>
                        </tr>
                      : null }
                     {/* Va 30 Years */}
                      { returnArray[6] ?
                        <tr>
                            <td><img src="https://d2w24n4g34usfg.cloudfront.net/lowratespr/images/LowRates-IconAsset4-8.png" className="d-none d-sm-none d-md-inline-block img-fluid" alt="LR Logo" /><Link smooth to="./fixedmortgage#30yrfixedva" target="_blank" rel="noopener noreferrer">30-Year Fixed</Link></td>
                            <td >
                              { Number(returnArray[6][3]).toFixed(3)+"%" }
                            </td>
                            <td >
                              { Number(returnArray[6][4]).toFixed(3)+"%" }
                            </td>
                            <td >
                            0.0000
                            </td>
                            <td >
                              <span className="modalLink" data-toggle="modal" data-target="#va30Year" onClick={this.handleShowVa30}>View</span>
                            </td>
                        </tr>
                      : null }

                      { returnArray[7] ? 
                        <tr className="text-center">
                            <th colSpan="5">
                                <h3>USDA</h3>
                                <small>100% LTV, 680 FICO, Primary Occupancy, Purchase</small>
                            </th>
                        </tr>
                      : null }
                    {/* USDA 30 Years */}
                      { returnArray[7] ?
                        <tr>
                            <td><img src="https://d2w24n4g34usfg.cloudfront.net/lowratespr/images/LowRates-IconAsset4-8.png" className="d-none d-sm-none d-md-inline-block img-fluid" alt="LR Logo" /><Link smooth to="./fixedmortgage#30yrfixedusda" target="_blank" rel="noopener noreferrer">30-Year Fixed</Link></td>
                            <td >
                               { Number(returnArray[7][3]).toFixed(3)+"%" }
                            </td>
                            <td >
                              { Number(returnArray[7][4]).toFixed(3)+"%" }
                            </td>
                            <td >
                            0.0000
                            </td>
                            <td >
                              <span className="modalLink" data-toggle="modal" data-target="#usda30Year" onClick={this.handleShowUsda30}>View</span>
                            </td>
                        </tr>
                      : null }
                    </tbody>
                </Table>
              </Col>
            </Row>
            </div>
            </div>
          </Container>
          </div>
        )
    }
}
