import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StyledHero from '../components/StyledHero'
import ConvImg from '../images/conventional.jpg';
import Banner from '../components/Banner';
import { FiCheckCircle } from 'react-icons/fi';
import { GoLinkExternal } from 'react-icons/go'
import { MdLocalPhone } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";




export default function Conventional(props) {

    return (
        <div id="conventionalWrapper">
            <Helmet>
                <title>Préstamo Convencionales</title>
                <meta name="description" content="A conventional mortgage or conventional loan is any type of homebuyer's loan that is not offered or secured by a government entity, but rather available through or guaranteed by a private lender." />
            </Helmet>
            <StyledHero img={ConvImg}>
                <Banner title="Convencional">
                </Banner>
            </StyledHero>
            <Container>
                <div className="wrapper-content bg-white rounded box-shadow">
                    <div className="pinside40 rounded">
                        <div className="product-tabs">
                            <Row>
                                <Col lg={12} className="nopadding">
                                    <div className="tab-content">
                                        <div role="tabpanel" className="tab-pane active" id="overview">
                                            <h2>Sobre Hipoteca Convencional</h2>
                                            <p>Una hipoteca Convencional o un préstamo Convencional es cualquier tipo de préstamo para la compra de tu hogar que no es ofrecido o asegurado por una entidad gubernamental, como el Federal Housing Administration (FHA), el Departamento de Asuntos de los Veteranos de los Estados Unidos (VA) o el Desarrollo Rural del Departamento de Agricultura de los Estados Unidos (USDA Rural Development). Estos están disponibles a través de o garantizado por un prestador privado (bancos, cooperativas, banco hipotecario) o las dos empresas respaldadas por el Gobierno, la Asociación Federal Nacional Hipotecaria (Fannie Mae) y la Corporación Federal de Préstamos Hipotecarios (Freddie Mac).</p>
                                            <h2>Convencional &quot;Conforming&quot;</h2>
                                            <p>Un préstamo Convencional &quot;Conforming&quot; es un préstamo cuyos términos y condiciones cumplen con los requisitos de Fannie Mae y Freddie Mac. Entre ellos se encuentra un límite por la cantidad máxima a financiar, fijado anualmente por el Federal Housing Finance Agency (FHFHA). Actualmente en Puerto Rico, un préstamo no puede exceder de $726,200 por 1 unidad. La hipoteca Convencional es la mejor opción para los compradores que desean la propiedad para inversión o como segundo hogar; o para aquellos que quieren comprar una casa con un precio mayor de $700,000.</p>
                                            <h3>Ventajas y Aspectos destacados del programa Convencional</h3>
                                            <ul className="listnone bullet">
                                                <li><FiCheckCircle className="bullet-check-circle-default" />Debe tener una puntuación de crédito de al menos 620 y preferiblemente, mayor de 700. Entre más alto el crédito, más bajo el interés del préstamo; las personas con una puntuación mayor de 740 tienen los mejores términos.</li>
                                                <li><FiCheckCircle className="bullet-check-circle-default" />DTI permitido según AUS, i.e., máximo 50%</li>
                                                <li><FiCheckCircle className="bullet-check-circle-default" />No se requiere seguro hipotecario si el pago inicial es del 20% o más.</li>
                                            </ul>
                                            <h2>Convencional &quot;Non-Conforming&quot;</h2>
                                            <p>Los préstamos &quot;Non-Conforming&quot; son hipotecas que no están sujetas a los límites mencionados arriba, al igual que a otros criterios relacionados al valor de tu crédito, capacidad financiera, estatus de los documentos, entre otros. Los préstamos &quot;Non-Conforming&quot; no son comprados por Fannie Mae.</p>

                                            <p>Para una lista de los documentos requeridos para el cierre, por favor procede a: <Link to="./Documents">DOCUMENTOS&nbsp;<GoLinkExternal /></Link></p>
                                            <p>Nuestros profesionales hipotecarios con amplia experiencia y licencias en hipotecas están listos para contestar cualquier pregunta que puedas tener. Por favor llámanos al <a href="tel:+17877869378"><MdLocalPhone />&nbsp;787&#8209;SUN&#8209;WEST.</a></p>
                                            <div className="text-center">
                                                <a className="btn btn-primary btns" onClick={() => {
                                                    props.setModalShow(true);
                                                }}>Solicita Ahora</a>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}
