import React, { useCallback, useState, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

import { BorrowerLeadGenerationTool } from "borrower-lead-generation-tool";
import styles from './index.module.css';

const GenerateLead = (props) => {
  // stores current formRef
  const [node, setNode] = useState("");
  // tracks changes to formRef and stores current with setNode
  const formRef = useCallback((node) => {
    setNode(node);
  }, []);

  // location.pathname used to determine offerLoanType and loanPurposeKeyword
  const location = useLocation();
  const [loanType, setLoanType] = useState("");
  const [loanPurpose, setLoanPurpose] = useState("");

  // useLayoutEffect changes loanType and loanPurpose when the pathname changes
  useLayoutEffect(() => {
    switch (location.pathname) {
      case "/Buying":
        setLoanType("");
        setLoanPurpose("PURCHASE");
        break;
      case "/Conventional":
        setLoanType("200");
        setLoanPurpose("");
        break;
      case "/Fha":
        setLoanType("110");
        setLoanPurpose("");
        break;
      case "/Refinancing":
        setLoanType("");
        setLoanPurpose("LIMITED CO");
        break;
      case "/Va":
        setLoanType("120");
        setLoanPurpose("");
        break;
      default:
        setLoanType("");
        setLoanPurpose("");
        break;
    }
  });

  return (
    <div className="formWrapper" id="generateLead">
      {node && (
        // dynamically renders the custom form header
        <div className="formHeader">
          {props.header ? props.header : "Por favor complete la siguiente información para continuar"}
        </div>
      )}
      <div className={styles.formBody}>
        <BorrowerLeadGenerationTool
          ref={formRef}
          leadSource="NET041"
          loanOfficerUid="CSTC"
          loanPurposeKeyword={loanPurpose}
          offerLoanType={loanType}
          statesDomain="lowratespr.com"
        />
      </div>
    </div>
  );
};

export default GenerateLead;
