
import React, { Suspense, useState } from "react";
import ReactGA from "react-ga";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Fha from "./pages/FHA";
import Conventional from "./pages/Conventional";
import Va from "./pages/Va";
import Renovation from "./pages/Renovation";
import Reverse from "./pages/Reverse";
import Buying from "./pages/Buying";
import Refinancing from "./pages/Refinancing";
import Documents from "./pages/Documents";
import Calculators from "./pages/Calculators";
import FAQ from "./pages/FAQ";
import Error from "./pages/Error";
import Terms from "./pages/Terms";
import FixedLoans from "./pages/FixedLoans";
import Sitemap from "./pages/Sitemap";
import Rates from "./pages/Rates";
import Disclaimer from "./pages/Disclaimer";
import Navigationbar from "./components/NavClass";
import Footer from "./components/Footer";
import ScrollUpButton from "react-scroll-up-button";
import ScrollToTop from "./components/ScrollToTop";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Fade from "react-bootstrap/Fade";
import Modal from "react-bootstrap/Modal";
import GenerateLead from "./components/GenerateLead";

const Team = React.lazy(() => import("./pages/Team"));

ReactGA.initialize('UA-131130661-11');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const [modalShow, setModalShow] = useState(false);
  const handleModal = () => setModalShow(false);

  return (
    <React.Fragment>
      <Router basename={process.env.PUBLIC_URL}>
        <ScrollToTop />
        <div id="ModalWrapper">
          <Fade in={modalShow}>
            <Modal size="lg" show={modalShow} onHide={handleModal}>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <GenerateLead />
              </Modal.Body>
            </Modal>
          </Fade>
        </div>
        <Navigationbar setModalShow={setModalShow} />
        <Route
          render={({ location }) => (
            <TransitionGroup>
              <CSSTransition key={location.key} timeout={250} classNames="fade">
                <Switch location={location}>
                  <Route exact path="/about">
                    <About />
                  </Route>
                  <Route exact path="/fha">
                    <Fha setModalShow={setModalShow} />
                  </Route>
                  <Route exact path="/conventional">
                    <Conventional setModalShow={setModalShow} />
                  </Route>
                  <Route exact path="/va">
                    <Va setModalShow={setModalShow} />
                  </Route>
                  <Route exact path="/renovation">
                    <Renovation setModalShow={setModalShow} />
                  </Route>
                  <Route exact path="/reverse">
                    <Reverse setModalShow={setModalShow} />
                  </Route>
                  <Route exact path="/rates">
                    <Rates />
                  </Route>
                  <Route exact path="/disclaimer">
                    <Disclaimer />
                  </Route>
                  <Route exact path="/team">
                    <Suspense fallback={<div>Loading...</div>}>
                      <Team />
                    </Suspense>
                  </Route>
                  <Route exact path="/buying">
                    <Buying setModalShow={setModalShow} />
                  </Route>
                  <Route exact path="/refinancing">
                    <Refinancing setModalShow={setModalShow} />
                  </Route>
                  <Route exact path="/documents">
                    <Documents />
                  </Route>
                  <Route exact path="/calculators">
                    <Calculators />
                  </Route>
                  <Route exact path="/faq">
                    <FAQ />
                  </Route>
                  <Route exact path="/fixedmortgage">
                    <FixedLoans />
                  </Route>
                  <Route exact path="/sitemap">
                    <Sitemap setModalShow={setModalShow} />
                  </Route>
                  <Route exact path="/terms">
                    <Terms />
                  </Route>
                  <Route exact path="/home">
                    <Home setModalShow={setModalShow} />
                  </Route>
                  <Redirect from="/" to="/home" />
                  <Route>
                    <Error />
                  </Route>
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          )}
        />

        <ScrollUpButton
          AnimationDuration={1000}
          ContainerClassName="scrollToTop"
        />
        <Footer />
      </Router>
    </React.Fragment>
  );
}

export default App;
