import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link, withRouter } from 'react-router-dom';
import { genericHashLink } from 'react-router-hash-link';
import './SubFooter.css'

const MyHashLink = (props) => genericHashLink(props, Link);

function SubFooter() {
    return (
        <section className="text-center box-shadow">
        <Container className="sub-footer p-2 p-sm-3 p-md-4">
            <Row className="sub-footer-row">
                <Col xl={12} className="bottomLinks">
                    <span>
                        <Link to="./">Home</Link> |
                        <Link title="List of license information of LowRates NMLS 3277" to="./disclaimer">Licencias</Link> |
                        <Link to="./Sitemap">Sitemap</Link> |
                        <a title="Privacy Policy" target="_blank" rel="noopener noreferrer" href="https://www.swmc.com/privacy-policy.php">Política de Privacidad</a> |
                        <Link to="./Terms">Términos y Condiciones</Link> |
                        <MyHashLink smooth to="./#contactUsSpan">Contáctanos</MyHashLink>
                    </span>
                </Col>
            </Row>
        </Container>
    </section>
    )
}

export default withRouter(SubFooter);