import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StyledHero from '../components/StyledHero'
import ReveseImg from '../images/reverse.jpg';
import Banner from '../components/Banner';
import { FiCheckCircle } from 'react-icons/fi';
import { GoLinkExternal } from 'react-icons/go'
import { MdLocalPhone } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";




export default function Reverse(props) {

    return (
        <div id="reveWrapper">
            <Helmet>
                <title>Préstamo Reverse</title>
                <meta name="description" content="A reverse mortgage is a loan available to homeowners, 62 years or older that allows them to convert part of the equity in their homes into cash." />
            </Helmet>
            <StyledHero img={ReveseImg}>
                <Banner title="Hipoteca Reverse">
                </Banner>
            </StyledHero>
            <Container>
                <div className="wrapper-content bg-white rounded box-shadow">
                    <div className="pinside40 rounded">
                        <div className="product-tabs">
                            <Row>
                                <Col lg={12} className="nopadding">
                                    <div className="tab-content">
                                        <div role="tabpanel" className="tab-pane active" id="overview">
                                            <h2>Sobre Hipoteca Reverse</h2>
                                            <p>Una hipoteca Reverse es un préstamo disponible para propietarios de una vivienda, mayores de 62 años, que les permite convertir parte del valor en exceso sobre la deuda (&quot;equity&quot;) de su casa en dinero en efectivo. Este producto ayuda a retirados con ingresos limitados a usar ese dinero acumulado para cubrir gastos mensuales básicos, pagar servicios de salud, o cualquier otra necesidad que tengan, ya que no existen restricciones el de uso de los fondos de la hipoteca Reverse.</p><p>Este préstamo se llama hipoteca Reverse porque, en vez de hacerle pagos mensuales a una institución financiera, como en el caso de las hipotecas tradicionales, el prestador le hace pagos al dueño de la casa.</p>
                                            <h3>Beneficios claves del programa Reverse</h3>
                                            <ul className="listnone bullet">
                                                <li><FiCheckCircle className="bullet-check-circle-default" />El prestatario no requiere pagar el préstamo en su totalidad hasta que el hogar sea vendido o esté vacante.</li>
                                                <li><FiCheckCircle className="bullet-check-circle-default" />No es necesario que el prestatario haga pagos mensuales mientras viva en esa propiedad.</li>
                                                <li><FiCheckCircle className="bullet-check-circle-default" />El dueño de la casa debe mantenerse al día con los impuestos y el seguro de la propiedad, y la cuota de la Asociación de Propietarios (si aplica).</li>
                                            </ul>

                                            <p>Para una lista de los documentos requeridos para el cierre, por favor procede a: <Link to="./Documents">DOCUMENTOS&nbsp;<GoLinkExternal /></Link></p>
                                            <p>Nuestros profesionales hipotecarios con amplia experiencia y licencias en hipotecas están listos para contestar cualquier pregunta que puedas tener. Por favor llámanos al <a href="tel:+17877869378"><MdLocalPhone />&nbsp;787&#8209;SUN&#8209;WEST.</a></p>
                                            <div className="text-center">
                                                <a className="btn btn-primary btns" onClick={() => {
                                                    props.setModalShow(true);
                                                }}>Solicita Ahora</a>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}
