import React from 'react';
import { Link } from 'react-router-dom';
import { genericHashLink } from 'react-router-hash-link';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FaHome } from "react-icons/fa";
import '../Navbar.css';

import './SideDrawer.css'




const MyHashLink = (props) => genericHashLink(props, Link);

const sideDrawer = props => {
    let drawerClasses = ['side-drawer'];
    if (props.show) {
        drawerClasses = 'side-drawer open';
    }


    return (
        <nav className={drawerClasses} >
            <div id="sideDrawerItems" className="sideDrawerItems">

                <Nav.Link as={Link} to="./" onClick={props.click} className="navLink">
                    Home <FaHome className="homeIcon" />
                </Nav.Link>
                <Nav.Link as={Link} onClick={props.click} to="./About">Sobre Nosotros</Nav.Link>
                <NavDropdown title="Préstamos" id="loanPrograms">
                    <NavDropdown.Item data-toggle="dropdown" className="navLink" onClick={props.click} as={Link} to="./Fha">FHA</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item to="./Conventional" onClick={props.click} as={Link} className="navLink">Convencionales</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={Link} className="navLink" onClick={props.click} to="./Va">VA</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item className="navLink" as={Link} onClick={props.click} to="./Renovation">Mejoras al Hogar</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item className="navLink" as={Link} onClick={props.click} to="./Reverse">Hipoteca Reverse</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Recursos" id="resources">
                    <NavDropdown.Item as={Link} to="./Buying" onClick={props.click} className="navLink">Compra Una Casa</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item className="navLink" onClick={props.click} as={Link} to="./Refinancing">Refinancia Tu Propiedad</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item className="navLink" onClick={props.click} as={Link} to="./Documents">Documentos</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item className="navLink" onClick={props.click} as={Link} to="./Calculators">Calculadoras</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link className="navLink" as={Link} onClick={props.click} to="./Team">Equipo</Nav.Link>
                <Nav.Item><MyHashLink className="navLink nav-link" onClick={props.click} smooth to="./#contactUsSpan">Contáctanos</MyHashLink></Nav.Item>
                <Nav.Link className="navLink" as={Link} onClick={props.click} to="./Faq">Preguntas Frecuentes</Nav.Link>
                <Nav.Link className="navLink" onClick={() => {
                    props.setModalShow(true);
                }}>Solicita Ahora</Nav.Link>
            </div>
        </nav>
    );
};

export default sideDrawer;