import React, { useState, useEffect, useCallback } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { genericHashLink } from 'react-router-hash-link';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image';
import { FaHome } from "react-icons/fa";
import UserWayAccessibilityMenu from "../UserWayAccessibilityMenu/index";
import './Navbar.css';

const MyHashLink = (props) => genericHashLink(props, Link);

const NavDesktop = (props) => {
    let [pos, setPos] = useState(window.pageYOffset)
    let [scroll, setScroll] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            let temp = window.pageYOffset

            setScroll(pos > 90);
            setPos(temp)
        };

        window.addEventListener('load', handleScroll);
        window.addEventListener("scroll", handleScroll);
        return (() => {
            window.removeEventListener("scroll", handleScroll);
        })
    })

    return (
        <>
            <Navbar id="mainNav" className={scroll ? "scrolled" : null} fixed="top" collapseOnSelect expand="lg" variant="dark">
                <Container>
                    <Row className="w-100 m-auto align-items-center">
                        <Col xs={9} sm={10} md={10} lg={3} xl={3} className="text-center text-sm-center text-md-center text-lg-left mr-3 mr-sm-4">
                            <Navbar.Brand className="mr-sm-0" as={Link} to="./">
                                <div className="d-flex accessibilitychanges">
                                    <div><a
                                        href="./"
                                        title="Sun West Mortgage Logo"
                                    >
                                        <Image className="img-fluid logoimage" srcSet="./assest/logo-sm.png 250w, 
                                ./assest/logo-md.png 350w, 
                                ./assest/logo-business.png 456w"
                                            sizes="(max-width: 580px) 250px,
                                       (max-width: 780px) 350px,
                                        456px"
                                            src="./assest/logo-business.png" alt="Sun West Mortgage Company, Inc. Logo" />
                                    </a>
                                    </div>
                                    <UserWayAccessibilityMenu color="white" />
                                </div>
                            </Navbar.Brand>
                        </Col>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="mr-auto">
                                <Nav.Item>
                                    <Nav.Link as={Link} to="./" className="navLink">
                                        Home <FaHome className="homeIcon" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link as={Link} to="./About">Sobre Nosotros</Nav.Link>
                                </Nav.Item>
                                <NavDropdown title="Préstamos" id="loanPrograms">
                                    <NavDropdown.Item className="navLink" as={Link} to="./Fha">FHA</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item to="./Conventional" as={Link} className="navLink">Convencionales</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} className="navLink" to="./Va">VA</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item className="navLink" as={Link} to="./Renovation">Mejoras al Hogar</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item className="navLink" as={Link} to="./Reverse">Hipoteca Reverse</NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title="Recursos" id="resources">
                                    <NavDropdown.Item as={Link} to="./Buying" className="navLink">Compra Una Casa</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item className="navLink" as={Link} to="./Refinancing">Refinancia Tu Propiedad</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item className="navLink" as={Link} to="./Documents">Documentos</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item className="navLink" as={Link} to="./Calculators">Calculadoras</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link className="navLink" as={Link} to="./Team">Equipo</Nav.Link>
                                <NavDropdown title="Contáctanos" id="contactNav">
                                    <NavDropdown.Item className="navLink" as={Link} to="./Faq">Preguntas Frecuentes</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <MyHashLink className="navLink dropdown-item" smooth to="./#contactUsSpan">Contáctanos</MyHashLink>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item className="navLink"
                                        onClick={() => {
                                            props.setModalShow(true);
                                        }}
                                    >
                                        Solicita Ahora</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </Row>
                </Container>
            </Navbar>
        </>
    );
}

export default withRouter(NavDesktop);
