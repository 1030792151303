import React, { Component } from 'react';
import axios from 'axios';
import ContactForm from './Contact'


class PostForm extends Component {
    
    state = {
        userName: '',
        userEmail: '',
        userPhone: '',
        emailSubject: '',
        message: '',
      }
      handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
      }
    handleSubmit =(e) =>{
        const submit = document.getElementById('submit')
        e.preventDefault();

        const { userName, userEmail, userPhone, emailSubject, userMessage } = this.state;

        axios.post(`https://f2b28znil7.execute-api.us-east-1.amazonaws.com/dev`, { userName, userEmail, userPhone, emailSubject, userMessage })
            .then(res => {
              submit.disabled = false
              console.log(res);
              console.log(res.data);
              this.setState({ 
                userName: '',
                userEmail: '',
                userPhone: '',
                emailSubject: '',
                message: '',});
            })
            .catch(function (error) {
                submit.disabled = false
                console.log(error);
              })
    }

    render(){
        return(
            <ContactForm handleSubmit={this.handleSubmit} handleChange={this.handleChange} />
        )
    }
 

}

export default PostForm;