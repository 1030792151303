/*
LOWRPR-39 updated contact component to use blgt.
Form submissions will send emails to lead.management@swmc.com
as well as lowratespr@swmc.com.
*/

import React, { useCallback, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Contact.css";
import "react-phone-number-input/style.css";
import { BorrowerLeadGenerationTool } from "borrower-lead-generation-tool";

export default function Contact() {
  // stores current formRef
  const [node, setNode] = useState(null);
  // tracks changes to formRef and stores current with setNode
  const formRef = useCallback((node) => {
    setNode(node);
  }, []);

  return (
    /* keeps original css for this form */
    <div id="contactUsForm">
      <Container>
        <Row>
          {/* conditinally renders header based on form status. */}
          {node && (
            <Col lg={12} className="text-center pt-5">
              <h2 className="pb-4 text-white">Contáctanos</h2>
            </Col>
          )}
          <Col className="m-auto text-center" id="contactLeadform" md={12} lg={8} xl={8}>
            <BorrowerLeadGenerationTool ref={formRef} leadSource="NET041" loanOfficerUid="CSTC"/>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
