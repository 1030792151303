import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import { IoIosPlay } from "react-icons/io";
import { Player } from 'video-react';
import Typewriter from 'typewriter-effect';
import './VideoModal.css';

export default function Video() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            <div >
                <Modal id="myModal2" size="lg" centered show={show} onHide={handleClose}>
                    <Modal.Body>
                    <div className="modal-video">
                        
                        <Player
                        playsInline
                        src="../Videos/ivanvideo.mp4"
                        autoPlay
                        fluid
    />           
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                    <span className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                    <span aria-hidden="true">&times;</span>
                    </span>
                    </Modal.Footer>
                </Modal> 
            </div>
            <section id="about" className="nexa-video-business bg-white">
            <div className="about-video-img">
                <Image src="./assest/ivan.jpg" alt="Video-img" className="about-img-video" />
            </div>
            <div className="about-video-shapes" id="videoShapeFrame">
                <Image src="./assest/background-shape.png" className="img-fluid" alt="background shape" />
            </div>
                <Container className="mb-0 mb-lg-4">
                    <Row>
                        <Col sm={12} md={3} lg={5} className="mt-md-4 mt-lg-0">
                            <div className="about-play-img">
                                <span data-target="#myModal2" data-toggle="modal" onClick={handleShow}>
                                    <i className="fa fa-play" aria-hidden="true"><IoIosPlay /></i>
                                </span>
                            </div>
                        </Col>
                        <Col sm={12} md={9} lg={7}>
                            <div className="video-about-detail">
                                <h5>
                                    <Typewriter
                                    options={{
                                    strings: ['Anécdota de Ivan Rodriguez'],
                                    autoStart: true,
                                    loop: true,}}/>
                                </h5>
                                <p>Ivan "Pudge" Rodriguez cuenta sus mejores recuerdos.</p>
                                <h2>SUS NUMEROS</h2>
                                <ul className="about-list">
                                    <li>
                                        <h3>68.7</h3>
                                        <h4>WAR</h4>
                                    </li>
                                    <li>
                                        <h3>2844</h3>
                                        <h4>Hits</h4>
                                    </li>
                                    <li>
                                        <h3>311</h3>
                                        <h4>HomeRuns</h4>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}
