import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StyledHero from '../components/StyledHero'
import VaImg from '../images/va.jpeg';
import Banner from '../components/Banner';
import { FiCheckCircle } from 'react-icons/fi';
import { GoLinkExternal } from 'react-icons/go'
import { MdLocalPhone } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";




export default function Va(props) {

    return (
        <div id="vaWrapper">
            <Helmet>
                <title>Préstamo VA</title>
                <meta name="description" content="Guaranteed by the U.S. Department of Veteran Affairs, the VA loan program is designed to assist both current active duty military members and veterans." />
            </Helmet>
            <StyledHero img={VaImg}>
                <Banner title="Hipoteca VA">
                </Banner>
            </StyledHero>
            <Container>
                <div className="wrapper-content bg-white rounded box-shadow">
                    <div className="pinside40 rounded">
                        <div className="product-tabs">
                            <Row>
                                <Col lg={12} className="nopadding">
                                    <div className="tab-content">
                                        <div role="tabpanel" className="tab-pane active" id="overview">
                                            <h2>Sobre Hipoteca VA</h2>
                                            <p>Garantizado por el Departamento de Asuntos de los Veteranos (VA) de los Estados Unidos, el préstamo VA está diseñado para asistir a miembros activos del ejército y veteranos.</p>
                                            <h3>Beneficios claves del programa VA</h3>
                                            <ul className="listnone bullet">
                                                <li><FiCheckCircle className="bullet-check-circle-default" />Disponible para todo el personal activo en el ejercito, veteranos, reserva y miembros de la Guardia Nacional, y sus cónyuges sobrevivientes, si no se vuelven a casar.</li>
                                                <li><FiCheckCircle className="bullet-check-circle-default" />No requiere pronto pago.</li>
                                                <li><FiCheckCircle className="bullet-check-circle-default" />No requiere pagos mensuales de seguro hipotecario.</li>
                                                <li><FiCheckCircle className="bullet-check-circle-default" />Disponible con intereses fijos y ajustables.</li>
                                                <li><FiCheckCircle className="bullet-check-circle-default" />Se aceptan puntuaciones de crédito desde 500.</li>
                                                <li><FiCheckCircle className="bullet-check-circle-default" />Provee la habilidad de financiar la Cuota de Financiamiento de VA en la hipoteca.</li>
                                                <li><FiCheckCircle className="bullet-check-circle-default" />Los miembros militares activos pueden ser elegibles para utilizar ciertos derechos militares para pagar su hipoteca.</li>
                                            </ul>
                                            <p>Sun West está aquí para ayudarte durante el proceso de solicitud para obtener tu préstamo VA y te ayudará a determinar si eres elegible.</p>

                                            <p>Para una lista de los documentos requeridos para el cierre, por favor procede a: <Link to="./Documents">DOCUMENTOS&nbsp;<GoLinkExternal /></Link></p>
                                            <p>Nuestros profesionales hipotecarios con amplia experiencia y licencias en hipotecas están listos para contestar cualquier pregunta que puedas tener. Por favor llámanos al <a href="tel:+17877869378"><MdLocalPhone />&nbsp;787&#8209;SUN&#8209;WEST.</a></p>
                                            <div className="text-center">
                                                <Link className="btn btn-primary btns" as={Link} to="./Calculators">Calculadora</Link>
                                                <a className="btn btn-primary btns" onClick={() => {
                                                    props.setModalShow(true);
                                                }}>Solicita Ahora</a>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}
