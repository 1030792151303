import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import StyledHero from '../components/StyledHero';
import AboutImg from '../images/about.jpg';
import Banner from '../components/Banner';
import {Helmet} from "react-helmet";


export default function About() {

    return (
        <div id="aboutWapper">
            <Helmet>
                <title>Sobre Nosotros</title>
                <meta name="description" content="For over 7 years, Sun West has been part of Puerto Rico's business community and community efforts" />
            </Helmet>
            <StyledHero img={AboutImg}>
                <Banner title="Sobre Nosotros">
                </Banner>
            </StyledHero>            
            <Container>
                <div className="wrapper-content bg-white rounded box-shadow">
                    <div className="pinside40 rounded">
                        <Row>
                            <Col sm={12} md={6} className="mb-5">
                                <h2 className="mb30 text-center">Quiénes Somos</h2>
                                <p>Sun West Mortgage Company, Inc., es un banco hipotecario ágil y amigable haciendo negocios en más de 48 estados, D.C., Puerto Rico y las Islas Vírgenes de los Estados Unidos. Las oficinas centrales se encuentran en California y Puerto Rico, y tiene operaciones en 48 estados en Estados Unidos. Sun West te ofrece un servicio integral para comprar un hogar o refinanciar una propiedad, con una amplia gama de productos hipotecarios, incluyendo préstamos Convencionales, FHA, VA, Mejoras al Hogar y Reverse Mortgage.</p>            
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="mb60 mb-sm-0">
                                    <h2 className="mb30 text-center">Nuestro Proceso</h2>
                                    <p>Nuestros Oficiales de Préstamos Hipotecarios (MLO’s, por sus siglas en inglés) te orientarán y ayudarán a solicitar y completar tu préstamo hipotecario. Podemos visitar tu hogar u oficina para hacerlo más fácil y conveniente.</p> 
                                </div>           
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6}>
                                <div className="text-center section-space60 pt-0 pt-sm-5">
                                    <h2 className="mb30">Responsabilidad Social</h2>
                                        <p>Por más de 8 años, Sun West ha formado parte de la comunidad de negocios y los esfuerzos comunitarios en Puerto Rico. La Compañía se enorgullece en ayudar a los demás a través de los miembros de su equipo, donaciones y responsabilidad social.</p>
                                        <p>Tras el paso del Huracán María en el 2017, el equipo de Sun West se unió para proveer comida y agua a muchas a comunidades necesitadas.</p>
                                        <p>Sun West también ayuda a equipos de pequeñas ligas, con el apoyo de su amigo y portavoz Iván &quot;Pudge&quot; Rodrígez, integrante del Salón de la Fama de béisbol.</p>
                                </div>
                            </Col>
                            <Col sm={12} md={6} className="text-center">
                                <Image src="./assest/ivanwithhand.jpg" className="img-thumbnail img-fluid box-shadow" alt="Ivan Rodriguez" />
                            </Col>
                        </Row>
                     </div>
                </div>
            </Container>              
        </div>
    )
}
